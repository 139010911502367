<template>
  <div class="WorkticketCheckGasDetail has-nav-bar">
    <WorkticketHeader
      title="气体检测详情"
      :show-gas-record="isSimple"
      @clickGasRecord="clickGasRecord"
    />

    <LoadView v-model="loadStatus" @retry="loadData()">
      <template v-if="!isSimple">
        <van-cell title="作业名称/内容">
          <router-link
            class="work-name"
            :to="`/workticket/${detail.ticketId}`"
            replace
          >
            {{ detail.ticketName }}
          </router-link>
        </van-cell>
        <van-cell title="作业区域" :value="detail.poiName" />
        <van-cell title="所属部门" :value="detail.departmentName" />
        <van-cell :title="TEXT_WORK_APPROVER" :value="detail.approverName" />
        <van-cell
          :title="TEXT_SAFETY_ANALYSIS_HEAD"
          :value="detail.supervisorName"
        />
        <van-cell title="作业类别" :value="detail.workTypeName" />
      </template>

      <van-cell title="检测位置" :value="detail.location" />
      <GasTablePlus
        v-if="isBy && isCustomType"
        :title="customCheck['1'].title"
        :selected-items.sync="customCheck['1'].monitorItemList"
        :sample-time.sync="customCheck['1'].sampleTime"
        :check-time.sync="customCheck['1'].checkTime"
        :editable="false"
      />
      <GasTablePlus
        v-if="isBy && isCustomType"
        :title="customCheck['2'].title"
        :selected-items.sync="customCheck['2'].monitorItemList"
        :sample-time.sync="customCheck['2'].sampleTime"
        :check-time.sync="customCheck['2'].checkTime"
        :editable="false"
      />
      <GasTablePlus
        v-if="isBy && isCustomType"
        :title="customCheck['3'].title"
        :gas-ids.sync="customCheck['3'].gasIds"
        :selected-items.sync="customCheck['3'].monitorItemList"
        :sample-time.sync="customCheck['3'].sampleTime"
        :check-time.sync="customCheck['3'].checkTime"
        :editable="false"
      />
      <FieldDateTimePicker
        v-if="isBy && !isCustomType"
        label="取样时间"
        :value.sync="detail.sampleTime"
        :editable="false"
      />
      <van-cell>
        <GasTable
          v-if="isBy && !isCustomType"
          :selected-items.sync="detail.items"
          :editable="false"
        />
      </van-cell>

      <van-cell title="检测结果">
        <span
          class="text-gas-check-result"
          :class="{ pass: detail.result == 1 }"
        >
          {{ detail.result ? "合格" : "不合格" }}</span
        >
      </van-cell>
      <FieldDateTimePicker
        v-if="isBy && !isCustomType"
        label="检测时间"
        :value.sync="detail.checkTime"
        :editable="false"
      />
      <van-cell :title="TEXT_WORK_ANALYST">
        <Tag
          :type="detail.personInfo.fileId ? 'green' : 'white'"
          :tick="!!detail.personInfo.fileId"
          @click.native="handleClickTag(detail.personInfo.fileId)"
        >
          {{ detail.personInfo.personName }}
        </Tag>
      </van-cell>
      <van-cell v-if="!isBy" title="检测时间" :value="detail.utime" />
    </LoadView>
  </div>
</template>

<script>
import WorkticketHeader from "@/components/header/WorkticketHeader";
import { LOAD_STATUS } from "@/components/loadview/utils";
import LoadView from "@/components/loadview/LoadView";
import { getWorkGasMonitorDetail } from "@/api/workticket";
import Tag from "@/components/Tag";
import esign from "@/components/esign";
import { fileUrl } from "@/utils/constant";
import GasTable from "./components/GasTable";
import FieldDateTimePicker from "./components/FieldDateTimePicker";
import GasTablePlus from "./components/GasTablePlus";

export default {
  name: "WorkticketCheckGasDetail",
  components: {
    LoadView,
    WorkticketHeader,
    Tag,
    GasTable,
    FieldDateTimePicker,
    GasTablePlus
  },
  data() {
    return {
      id: "",
      loadStatus: LOAD_STATUS.LOADING,
      LOAD_STATUS,
      monitorItemList: 3,
      detail: {
        personInfo: {}
      },
      simple: 0, //是否显示简化的数据，1=简化显示（点击检测按钮）
      customCheck: {
        "1": {
          title: "取样位置：上（左）",
          sampleLocation: 1,
          monitorItemList: [],
          sampleTime: "",
          checkTime: "",
          gasIds: []
        },
        "2": {
          title: "取样位置：中",
          sampleLocation: 2,
          monitorItemList: [],
          sampleTime: "",
          checkTime: "",
          gasIds: []
        },
        "3": {
          title: "取样位置：下（右）",
          sampleLocation: 3,
          monitorItemList: [],
          sampleTime: "",
          checkTime: "",
          gasIds: []
        }
      }
    };
  },
  computed: {
    isSimple() {
      return this.simple == 1;
    },
    isCustomType() {
      return this.detail.sampleStatus;
    }
  },
  created: function() {},
  mounted: function() {
    let { id } = this.$route.params;
    let { simple } = this.$route.query;
    this.id = id;
    this.simple = simple;

    this.loadData();
  },
  methods: {
    gasTypeIconSwitch(item) {
      const m = {
        1001: "keranqiti",
        2001: "a-dupinweixiangutoukulou",
        3001: "rongjieyang_mian"
      };
      return m[item.gasType] || "qitahuaxuewuzhi";
    },
    handleClickTag(fileId) {
      if (fileId) {
        esign.showImage(fileUrl + fileId);
      }
    },
    loadData() {
      getWorkGasMonitorDetail(this.id)
        .then(res => {
          const info = res.workers?.[0];
          this.detail = {
            ...res,
            approverName: res.approvers?.map(v => v.name).join(",") || "",
            supervisorName: (res.supervisors && res.supervisors[0].name) || "",
            personInfo: {
              ticketId: info.ticketId,
              personType: info.workerType,
              personId: info.workerId,
              personName: info.workerName,
              fileId: info.fileId,
              type: info.orgType
            }
          };
          res.monitorSampleList.forEach(item => {
            this.customCheck[item.sampleLocation].monitorItemList =
              item.monitorItemList;
            this.customCheck[item.sampleLocation].sampleTime = item.sampleTime;
            this.customCheck[item.sampleLocation].checkTime = item.checkTime;
          });
          console.log(333, this.customCheck);
          this.loadStatus = LOAD_STATUS.SUCCESS;
        })
        .catch(error => {
          this.loadStatus = LOAD_STATUS.ERROR;
        });
    },
    clickGasRecord() {
      this.$router.push("/workticket/checkgasRecord/" + this.detail.specialId);
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.WorkticketCheckGasDetail {
  .work-name {
    color: $--color-primary;
  }
}
</style>
